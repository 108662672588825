import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
import { MdOutlineClose as Cross } from "react-icons/md"
import { MdWest, MdEast } from "react-icons/md"
import { CgDanger } from "react-icons/cg"
import { MdOutlineReportProblem } from "react-icons/md"
import {GrStatusGood}from "react-icons/gr"
import {AiTwotoneCheckCircle}from "react-icons/ai"


import ListItem from "./ListItem"



const PerksModuleDove = () => {
    return (
        <PerksModuleStyles className="section section__padding">
            {/* <StaticImage
        className="perks__image--bg"
        src="../../../static/foglie_bg.jpg"
        alt="Foglie"
        layout="constrained"
        transformOptions={{duotone: { highlight: "#ffc400", shadow: "#ffc400", opacity: 100 } }}
        placeholder="tracedSVG"
      /> */}
            <div className="perks__image--overlay"></div>
            <div className="container container__tight">
                <ListItem
                    title={["È consigliato <strong>contattare i gestori del rifugio</strong> per supporto e consigli.","È consigliato rivolgersi a delle <strong>guide professionali</strong> per esperienze in totale sicurezza"]}
                    icons={[<AiTwotoneCheckCircle/>,<AiTwotoneCheckCircle/>]}
                    content="scopri come"
                >
                     <span className="iconBig"> <GrStatusGood/></span>
                </ListItem>
                <span className="perks__divider">
                    {/* <Cross /> */}

                </span>
                    <ListItem
                    
                        title={[`<strong>È sconsigliato</strong> avventurarsi in montagna senza la dovuta attrezzatura.`,"<strong>È sconsigliato</strong> intraprendere il sentiero se non si è in grado di orientarsi  in   montagna."]}
                        icons={[<AiTwotoneCheckCircle/>,<AiTwotoneCheckCircle/>]}

                        content="prenota ora"
                    >
                    
                    <span className="iconBig"> <MdOutlineReportProblem/></span>
                    {/* <StaticImage
                        src="../../../static/book.png"
                        alt="Perk Image"
                        layout="fullWidth"
                        placeholder="tracedSVG"
                    /> */}
                </ListItem>
            </div>
        </PerksModuleStyles>
    )
}

export default PerksModuleDove
