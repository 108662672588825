
import React, { useState, useEffect } from "react"
import BannerModule from "../BannerModule/BannerModule"

const BannerModuleWrapper = ({ max, titles, subtitles,linkUrls, linkTexts, images, innerRef,punto }) => {
  const [imageNum, setImageNum] = useState(0)
  const [maxImage, setMaxImage] = useState(max)
  
  const [opacity, setOpacity] = useState(0)
  const children = null
  const price = null
  const enquire = null
  const random = Math.floor(Math.random() * (3 - 0 + 1) + 0)
  let myTimeout = null

  useEffect(() => {
   
    startTimeToChange();
    
  });

  

  const startTimeToChange = () => {
   
    //myTimeout = setTimeout(() => changeSlide, 8000);
    myTimeout = setTimeout(changeSlide, 8000)
    /* console.log("myTimeoutStart",myTimeout) */
  }

  const changeSlide = () => {
     /* console.log("changeSlide!! ")  */
    /* console.log('Aggiorno Stato') */
    if (imageNum < maxImage) {
      //console.log("changeSlide",imageNum + 1) */
      setImageNum(imageNum + 1);
    } else {
      setImageNum(0)
    }
     

    
  }

  //console.log(this.props.innerRef,this.props.inView)
  
  return (
    <div id="mainBg" ref={innerRef} >
      {/* {<h2>Element is inside the viewport: {inView.toString()}</h2>} */}
      <BannerModule
        title={titles[imageNum]}
        subTitle={subtitles[imageNum]}
        link={linkTexts[imageNum]}
        linkUrl = {linkUrls[imageNum]}
        images={images[imageNum].localFile}
        opacity={0}
        punto= {punto ? punto : "."}
        imageType="dynamic"
      />
    </div>
  )
}

export default BannerModuleWrapper