import * as React from "react"
import { PerkStyles } from "./PerksModuleStyles"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Perk = ({ children, title, content, link }) => {
  return (
    <PerkStyles>
      {children}
      {title && <h4>{title}</h4>}
      {content && <p> <Link to={link??"#"} >
                    {content}
                    
                  </Link></p>}
    </PerkStyles>
  )
}

export default Perk
