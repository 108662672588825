import * as React from "react"
import { PerkStyles } from "./PerksModuleStyles"

const ListItem = ({ children, title, content, icons }) => {
    function creaCodiceHtml(string) {
        return { __html: string };
    }
    return (
        <PerkStyles>
            {children}

            {
                title.map((titolo, index) => {
                    return (
                        <div className="list-el" key={titolo}>
                            <span>{icons[index]}</span>
                            <p dangerouslySetInnerHTML={creaCodiceHtml(titolo)}/>
                        </div>
                    )
                })
            }


        </PerkStyles>
    )
}

export default ListItem
