import * as React from "react"
import Button from "../Button/Button"
import { ContactStyles } from "./ContactStyles"
import { FaPencilAlt } from "react-icons/fa"
import { ImPointDown } from "react-icons/im"


const Contact = () => {
  return (
    <ContactStyles className=" section__padding_bottom">
      
      <div id="form_header" className="d-flex header-contact">
        <div className="d-flex">
          <p>Per avere informazioni o prenotarti puoi usare la form qui sotto</p>
          <span className="dot">.</span>
        </div>
        <ImPointDown className="point-down" style={{marginLeft:"10px"}}/>
      </div>

      <form  method="post" netlify-honeypot="bot-field" action="/messaggio-inviato" data-netlify="true" name="contact">
        <input placeholder="Il tuo nome..." type="text" name="name" />
        <input placeholder="La tua email..." type="email" name="email" />
        <textarea
          placeholder="Il tuo messaggio"
          name="message"
          rows="5"
        ></textarea>
        <Button type="submit" text="Invia richiesta" />
        <input type="hidden" name="form-name" value="contact" />
      </form>
    </ContactStyles>
  )
}

export default Contact
