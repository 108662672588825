import styled from "styled-components"

export const ContactRefStyles = styled.section`
  a {
    margin-left: 5px;
   
    color: #000;
    text-decoration: none ;
    transition: color 0.3s ease;
    position: relative;
    font-weight: 700;
    
    &:after {
      // whatever you want with normal CSS syntax. Here, a custom orange line as example
      content: "";
      display: block;
      position: absolute;
      height: 3px;
      left: 0;
      right: 0;
      bottom: 0 px;
      background-color: var(--primary);
      -webkit-transition: left 0.3s ease;
      transition: left 0.3s ease;
    }
  }

  a:hover {
    cursor: pointer;
    color: var(--primary);

    &:after {
        left: 100%;
    }
  }

  .mail-row, .last-phone-row {
      padding-bottom:50px
      
  }

  .gps-row {
    flex-direction: column;
    align-items: flex-start;

    .coords {
        margin-left:5px;
        margin-top:10px;
    }
    
  }

  .last-gps-row {
    padding-bottom:50px;
  }

  .contactForm.sx {
      max-width:400px;
      p {
        max-width:80%;
      }
    @media (max-width: 768px) {
        p {
            max-width:100%;
        }
    }
  }

  .contactForm.dx {
    width:80%;
    @media (max-width: 768px) {
        width:100%;
    }
  }

  a.tel {
    font-size: var(--h5);
  }

  svg {
    width: 30px;
    height: 30px;
  }

  .link-name {
      min-width:60px
  }

  
  
`

