import Contact from "../components/Contact/Contact"
import { ContactRefStyles } from "../components/Contact/ContactRefStyles"
import React, { useEffect } from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModuleWrapper from "../components/BannerModule/BannerModuleWrapper"
import { graphql } from 'gatsby'
import PerksModuleDove from "../components/PerksModule/PerksModuleDove"
import Perk from "../components/PerksModule/Perk"
import { AiOutlineMail } from "react-icons/ai"
import { BsTelephone } from "react-icons/bs"
import { GrMapLocation } from "react-icons/gr"
import { Link } from "gatsby"

import styled from "styled-components"

export default function ContactPage({ data }) {

 


  const titles = ["Contatti", "Rifugio Cognolo", "Rifugio Cognolo", "Rifugio Cognolo"]
  const subtitles = ["Mandaci una mail, telefona oppure usa la form qui sotto", "Un luogo dove fare esperienza e vivere la relazione con la natura in maniera diversa", "È raggiungibile solo a piedi con minimo 2 ore di cammino", "È aperto tutto l'anno solo su prenotazione"]
  const linkTexts = ["scopri come", "Scopri le attività", "Come raggiungerci", "Prenota"]
  const linkUrls =["#form","/attivita","#","#"]




  return (
    <>
      <Seo title="Contatti" />
      <Layout>
        {<BannerModuleWrapper
          max={0}
          titles={titles}
          subtitles={subtitles}
          linkTexts={linkTexts}
          images={data.allWp.edges[0].node.cognoloSettings.backgrounds_contatti.bgsContatti}
          linkUrls={linkUrls}
        />}

        <PerksModuleDove>
          <Perk title="The Title" content="The content" />
        </PerksModuleDove>

        <ContactRefStyles className="section__padding_bottom section">
          <div className="d-flex wrap-col">
            <div className="contactForm sx">
              <AiOutlineMail />
              <div className="d-flex mail-row" style={{ alignItems: "center" }}>
                <a style={{ marginLeft: "5px" }} href="mailto:info.cognolorefuge@gmail.com">info.cognolorefuge@gmail.com </a>

              </div>
              <BsTelephone />
              <div className="d-flex phone-row last-phone-row" style={{ alignItems: "center", justifyContent: "flex-start" }}>
                <span className="link-name" >Elisa</span><a className="tel" href="tel:+3207685731">320.7685731</a>
              </div>


             

              <GrMapLocation />
              <div className="d-flex gps-row last-gps-row" >
                 <Link to="/dove"activeClassName="menu__item--active">
                    Coordinate Geografiche
                    <span>.</span>
                  </Link><span ></span><span className="coords">Lat: 2.353215, Lon: 412.716586 </span>

              </div>
             
            </div>
            <div className="contactForm dx" id="form">
              <Contact />
            </div>
          </div>
        </ContactRefStyles>

      </Layout>
    </>
  )
}



export const imagesWp = graphql`
{
  allWp {
    edges {
      node {
        cognoloSettings {
          pageSlug
          pageTitle
          backgrounds_contatti  {
            bgsContatti {
              localFile {
                childrenImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.7
                    placeholder: BLURRED
                    formats: AUTO
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
}
`