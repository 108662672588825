import styled from "styled-components"

export const PerksModuleStyles = styled.section`
  background-color: var(--primary);
  position: relative;

  .iconBig svg {
    width: 70px;
    height: 70px;
  }

  .perks__image--bg,
  .perks__image--overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .perks__image--bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .perks__image--overlay {
    background-color: rgba(255, 255, 255, 0);
  }

  .perks__divider {
    /* font-size: 25px; */
    .text {
      font-size: var(--bannerTitle);
      font-weight:700;
    }
    .icons {
      display:none;
      justify-content:space-between
    }
    .icon-top svg, .icon-bottom svg {
      height: 30px;
      width:100% ;
    }

    color: #000;
    text-align:center;

    @media (min-width: 1024px) {
      font-size: 35px;
    }

   
    @media (min-width: 768px) {
      .icon-top, .icon-bottom {
        display:none
      }
      .icons {
        display:flex;
      }
    }
  }

  > .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items:start;
    gap: var(--gap);
    align-items: center;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      
    }

    @media (min-width: 1024px) {
      gap: calc(var(--gap) * 2);
    }

    @media (min-width: 1200px) {
      gap: calc(var(--gap) * 4);
    }
  }
`

export const PerkStyles = styled.div`
  max-width: 275px;
  text-align: center;

  @media (min-width: 414px) {
    max-width: 325px;
  }

  @media (min-width: 768px) {
    flex: 1 1 50%;
  }

  @media (min-width: 1024px) {
    max-width: 550px;
  }

  a {
    color: black;
    font-weight: 700;
    padding: 2px 5px;

    :hover{
      background:black;
      color:var(--primary);
    }
  }

  h3 {
    display: inline-block;
    border-bottom: 2px solid rgba(255, 255, 255, 0.15);
  }

  .list-el {
    display:flex;
    svg {
      width: 15px;
      height: 7px;
    }
  }

  p {
    margin-bottom: 0;
    text-align:left;
    margin-top: 0;
    font-size: 24px;
    border-bottom: 2px solid var(--primary);
    font-weight: 400;
    display: inline-block;
    color: #333;
  }
  .gatsby-image-wrapper {
    width: 300px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto calc(var(--gap) / 2) auto;

    @media (min-width: 375px) {
      width: 200px;
    }

    @media (min-width: 1024px) {
      width: 300px;
      height: 140px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain !important;
      object-position: center;
    }
  }
`
