import * as React from "react"
import { Component } from 'react'
import { Link, navigate } from "gatsby"
import { BannerModuleStyles } from "./BannerModuleStyles"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../Button/Button"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { motion, MotionConfig } from "framer-motion"


//
const BannerModule = function(props) {
  function scrollToArea(e) {
    console.log("navigate")
    //navigate("/#approfondisci")
  }
  /* console.log('Render banner', props) */
  //const image = getImage(props.images[this.state.imageNum].localFile.childrenImageSharp[0])
  const image = getImage(props.images.childrenImageSharp[0])
  const children = null
  const price = null
  const enquire = null
  return (
    
    <motion.div layout
          key={image.images.fallback.src}
          //style={{ opacity: props.opacity }}
          initial={{ opacity:props.opacity  }}
          animate={{
            opacity: 1
          }}
          transition={{ duration: .6 }}
        >
          <>
            <BannerModuleStyles>
            <div className="mask">
              {props.imageType == "static" ? (
                children
              ) : (
                  <GatsbyImage
                    className="banner__image"
                    imgClassName="banner__image--content"
                    image={image}
                    objectFit="cover"
                    alt={"testo alt"}
                  />

                )}

              </div>

              <div className="container">
                <div className="banner__content">
                  {props.title && (
                    <h1>
                      {props.title}
                  <span style={{ color: "var(--primary)" }}>{props.punto}</span>
                    </h1>
                  )}
                  {props.subTitle && <h2>{props.subTitle}</h2>}
                  <div className="banner__btns" >
                   
                    {/* <Button  onClick={console.log("dadsadada")} text={props.link} /> */}
                    <a className="button-link" href={props.linkUrl}>{props.link}</a>
                  </div>
                </div>
              </div>
              <div className="gradient"></div>
            </BannerModuleStyles>
            <span id="topContent"></span>
          </>
        </motion.div>
  )
}
export default BannerModule