import styled from "styled-components"

export const ContactStyles = styled.section`
  max-width: 750px;
  box-sizing: content-box;
  margin-left: auto;
  margin-right: 0px;

  .dot {
    color: var(--primary);
  }

  form {
    display: flex;
    flex-direction: column;
  }

  button {
    align-self:flex-end;
    @media (max-width: 768px) {
      align-self:flex-start;
    }
  }
  
  .header-contact {
    justify-content: start;
    align-items: center;
    border-radius: 6px;
    border: 2px solid white;
    padding: 0px;
    margin-bottom: 15px;
  }

  p {
    font-size:20px;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  input {
    height: calc(var(--gap) * 2);
    margin-bottom: var(--gap);
  }

  input,
  textarea {
    background-color: #ececec;
    color: #333;
    border: none;
    outline: 2px solid rgba(255, 255, 255, 0.10);
    border-radius: 6px;
    width: 100%;
    font-size: var(--p);
    font-weight: 700;
    font-family: "Heebo", sans-serif;
    padding: 15px;
    transition: outline 0.3s ease;

    &:focus {
      outline: 2px solid var(--primary);
    }

    &::-webkit-input-placeholder {
      color: var(--inActive);
    }

    &::-moz-placeholder {
      color: var(--inActive);
    }

    &:-ms-input-placeholder {
      color: var(--inActive);
    }

    &:-moz-placeholder {
      color: var(--inActive);
    }
  }
  textarea {
    margin-bottom: var(--gap);
  }
`
